const hiragana = [
    {
        "dec": 12353,
        "hex": "3041",
        "char": "ぁ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL A",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M543 -379C543 -316 552 -252 564 -195C515 -155 471 -139 434 -139C401 -139 383 -154 383 -195C383 -271 454 -360 543 -406Z",
            "M573 -377C573 -391 573 -405 574 -419C614 -436 666 -451 719 -451C727 -451 736 -451 744 -450C706 -347 655 -276 591 -218C580 -270 573 -325 573 -377Z",
            "M568 -700C568 -694 567 -684 566 -673C564 -653 561 -627 558 -596C537 -595 516 -595 495 -595C464 -595 401 -599 374 -604L375 -570C403 -567 468 -565 495 -565C514 -565 534 -565 555 -566C551 -527 547 -484 545 -439C441 -392 350 -289 350 -193C350 -139 383 -109 433 -109C473 -109 523 -127 572 -162C576 -142 582 -124 587 -108L617 -117C611 -137 604 -160 599 -184C670 -246 731 -331 776 -445C864 -426 912 -361 912 -297C912 -172 799 -104 670 -93L688 -64C877 -92 943 -193 943 -295C943 -368 893 -448 786 -472C789 -482 793 -492 796 -502C799 -512 800 -516 803 -524L768 -533C767 -527 764 -515 762 -508C759 -497 756 -487 753 -477C741 -478 729 -479 716 -479C678 -479 624 -470 575 -452C577 -493 580 -532 584 -568C677 -573 777 -584 865 -598L864 -630C775 -613 682 -602 588 -597C591 -627 595 -652 598 -671C600 -680 603 -693 605 -699Z"
        ]
    },
    {
        "dec": 12354,
        "hex": "3042",
        "char": "あ",
        "romanization": "a",
        "name": "HIRAGANA LETTER A",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M450 -772L410 -773C410 -766 410 -753 408 -738C405 -714 402 -680 397 -640C367 -639 336 -638 307 -638C282 -638 205 -643 164 -650L165 -616C199 -613 276 -610 308 -610C336 -610 365 -611 395 -612C390 -560 385 -501 382 -443C252 -385 136 -257 136 -131C136 -64 177 -28 234 -28C285 -28 351 -54 411 -99C417 -74 424 -50 431 -27L464 -37C454 -64 446 -92 439 -121C538 -204 611 -314 666 -458C783 -435 848 -353 848 -262C848 -106 707 -18 542 0L560 29C776 -6 879 -118 879 -260C879 -362 809 -455 675 -482C680 -496 684 -509 689 -523C693 -535 696 -543 700 -551L664 -562C663 -551 660 -542 658 -533C654 -517 649 -502 644 -487C628 -489 611 -490 593 -490C544 -490 474 -477 412 -455C415 -510 420 -565 425 -613C543 -619 671 -632 782 -650L781 -682C689 -662 556 -647 428 -641C433 -680 437 -713 441 -737C443 -748 446 -762 450 -772Z",
            "M409 -354C409 -377 410 -401 410 -425C461 -446 529 -464 598 -464C611 -464 624 -463 636 -462C587 -325 514 -225 431 -153C417 -219 409 -287 409 -354Z",
            "M167 -133C167 -242 265 -357 381 -412C380 -392 380 -372 380 -352C380 -278 389 -202 404 -130C341 -81 281 -59 233 -59C192 -59 167 -85 167 -133Z"
        ]
    },
    {
        "dec": 12355,
        "hex": "3043",
        "char": "ぃ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL I",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M395 -635L356 -636C359 -624 359 -582 359 -566C359 -522 361 -396 372 -330C400 -156 464 -105 522 -105C567 -105 613 -153 658 -282L628 -305C600 -209 560 -140 521 -140C467 -140 424 -213 403 -334C392 -405 391 -522 391 -569C391 -587 394 -626 395 -635Z",
            "M811 -610L780 -598C847 -510 899 -375 913 -220L946 -234C932 -374 879 -520 811 -610Z"
        ]
    },
    {
        "dec": 12356,
        "hex": "3044",
        "char": "い",
        "romanization": "i",
        "name": "HIRAGANA LETTER I",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M200 -676L157 -678C162 -663 162 -621 162 -603C162 -549 164 -425 173 -346C200 -101 285 -11 362 -11C416 -11 476 -68 529 -228L501 -250C469 -131 416 -45 363 -45C285 -45 220 -165 203 -350C195 -440 193 -547 195 -606C196 -629 198 -665 200 -676Z",
            "M729 -644L700 -632C780 -524 846 -358 865 -164L896 -176C877 -358 811 -529 729 -644Z"
        ]
    },
    {
        "dec": 12357,
        "hex": "3045",
        "char": "ぅ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL U",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M841 -352C841 -196 696 -115 515 -91L535 -62C748 -97 872 -197 872 -351C872 -442 803 -509 691 -509C615 -509 523 -484 464 -470C444 -465 421 -461 402 -460L416 -419C429 -424 447 -430 471 -438C523 -454 606 -479 691 -479C764 -479 841 -441 841 -352Z",
            "M498 -682L492 -652C577 -636 739 -616 808 -613L813 -645C733 -647 580 -666 498 -682Z"
        ]
    },
    {
        "dec": 12358,
        "hex": "3046",
        "char": "う",
        "romanization": "u",
        "name": "HIRAGANA LETTER U",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M748 -339C748 -142 569 -38 336 -5L358 26C593 -13 779 -124 779 -339C779 -466 680 -534 557 -534C446 -534 333 -499 267 -484C240 -478 215 -473 194 -471L207 -428C226 -436 245 -443 277 -453C343 -471 438 -503 557 -503C666 -503 748 -440 748 -339Z",
            "M311 -753L306 -721C414 -701 597 -681 700 -674L705 -706C616 -708 413 -729 311 -753Z"
        ]
    },
    {
        "dec": 12359,
        "hex": "3047",
        "char": "ぇ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL E",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M480 -694L474 -663C557 -644 716 -623 780 -618L785 -651C732 -654 561 -671 480 -694Z",
            "M786 -493L762 -517C755 -514 747 -512 735 -510C682 -502 493 -486 444 -484C423 -483 401 -484 385 -485L389 -445C405 -449 424 -451 447 -453C497 -457 666 -472 722 -477C650 -405 383 -141 359 -118C351 -109 342 -101 336 -97L369 -74C404 -119 514 -231 545 -259C564 -276 579 -284 601 -284C638 -284 651 -263 661 -234C667 -215 678 -165 686 -142C700 -89 736 -73 790 -73C846 -73 905 -80 936 -85L938 -122C905 -111 833 -103 792 -103C751 -103 729 -113 717 -147C710 -167 698 -213 690 -239C678 -280 657 -311 612 -311C605 -311 597 -310 592 -309C638 -356 746 -461 762 -475C767 -478 777 -486 786 -493Z"
        ]
    },
    {
        "dec": 12360,
        "hex": "3048",
        "char": "え",
        "romanization": "e",
        "name": "HIRAGANA LETTER E",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M316 -763L310 -731C432 -706 592 -684 686 -675L691 -707C605 -712 419 -737 316 -763Z",
            "M695 -509L674 -539C665 -535 650 -531 635 -529C569 -520 335 -499 269 -498C241 -497 219 -497 196 -499L199 -458C221 -461 241 -463 272 -465C336 -470 543 -489 630 -494C526 -396 194 -66 166 -38C154 -25 142 -15 136 -10L172 15C216 -41 355 -185 394 -220C416 -240 441 -251 476 -251C509 -251 531 -229 542 -196C553 -164 571 -94 580 -64C598 3 645 19 711 19C765 19 844 11 887 4L889 -36C845 -22 764 -12 710 -12C647 -12 624 -28 610 -72C598 -108 582 -171 572 -202C558 -246 531 -276 483 -276C473 -276 455 -274 443 -272C493 -323 621 -442 651 -472C657 -478 685 -501 695 -509Z"
        ]
    },
    {
        "dec": 12361,
        "hex": "3049",
        "char": "ぉ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL O",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M381 -198C381 -242 453 -296 514 -326C515 -260 517 -190 517 -155C517 -136 512 -115 488 -115C452 -115 381 -153 381 -198Z",
            "M791 -595C839 -569 916 -515 956 -475L976 -501C940 -534 859 -591 809 -618Z",
            "M516 -704C518 -693 519 -679 519 -665C519 -648 518 -603 517 -551C491 -549 466 -548 444 -548C428 -548 402 -549 355 -556L356 -522C403 -519 424 -518 444 -518C466 -518 490 -520 516 -522C515 -475 514 -425 514 -386V-357C453 -331 348 -265 348 -193C348 -139 441 -84 492 -84C530 -84 548 -104 548 -153C548 -195 546 -269 545 -339C605 -363 667 -378 734 -378C807 -378 880 -340 880 -266C880 -182 820 -140 740 -124C707 -117 672 -117 648 -118L658 -86C677 -86 711 -88 743 -94C854 -116 911 -177 911 -266C911 -347 835 -406 734 -406C671 -406 607 -392 545 -369V-389C545 -429 546 -479 546 -526C619 -535 694 -549 722 -556L721 -590C672 -574 608 -561 547 -554C548 -605 549 -649 550 -666C551 -676 551 -690 553 -704Z"
        ]
    },
    {
        "dec": 12362,
        "hex": "304a",
        "char": "お",
        "romanization": "o",
        "name": "HIRAGANA LETTER O",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M170 -137C170 -192 250 -265 348 -307C349 -222 351 -130 351 -82C351 -52 339 -30 311 -30C263 -30 170 -82 170 -137Z",
            "M700 -644C764 -607 855 -545 905 -493L925 -520C883 -562 783 -631 718 -668Z",
            "M349 -778C352 -763 353 -745 353 -726C353 -709 352 -652 350 -586C316 -583 284 -581 257 -581C221 -581 193 -583 143 -589L144 -555C176 -552 211 -550 257 -550C283 -550 315 -552 349 -556C348 -495 347 -430 347 -381V-338C242 -293 136 -211 136 -136C136 -64 256 4 314 4C356 4 381 -22 381 -83C381 -135 378 -232 377 -319C462 -352 533 -369 619 -369C719 -369 810 -319 810 -220C810 -110 718 -59 626 -40C586 -31 546 -32 515 -33L526 1C555 1 598 0 640 -10C755 -35 843 -101 843 -221C843 -321 756 -398 619 -398C545 -398 459 -381 377 -350V-384C377 -433 378 -498 380 -559C458 -568 544 -584 603 -598L602 -632C542 -614 458 -598 381 -589C383 -654 385 -710 385 -728C386 -750 387 -762 389 -778Z"
        ]
    },
    {
        "dec": 12363,
        "hex": "304b",
        "char": "か",
        "romanization": "ka",
        "name": "HIRAGANA LETTER KA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M417 -764L378 -767C377 -697 365 -614 349 -549C281 -543 212 -537 178 -536C151 -535 134 -535 107 -536L112 -497C133 -500 163 -503 180 -504C212 -506 276 -513 342 -519C313 -398 233 -144 128 -5L162 8C279 -180 340 -385 375 -522C432 -527 486 -531 517 -531C581 -531 628 -511 628 -410C628 -295 610 -158 576 -81C551 -29 518 -24 479 -24C449 -24 402 -29 358 -44L363 -7C392 -1 439 7 476 7C529 7 572 -4 601 -66C642 -145 659 -298 659 -415C659 -539 590 -560 518 -560C492 -560 439 -556 382 -551C396 -621 407 -705 411 -729C413 -743 415 -754 417 -764Z",
            "M754 -648L726 -632C794 -557 882 -371 912 -268L944 -283C909 -383 816 -574 754 -648Z"
        ]
    },
    {
        "dec": 12365,
        "hex": "304d",
        "char": "き",
        "romanization": "ki",
        "name": "HIRAGANA LETTER KI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M521 -703C526 -685 531 -665 538 -643C443 -635 319 -638 200 -653L203 -618C324 -607 451 -605 547 -614C556 -583 567 -550 579 -519C587 -498 595 -475 604 -453C493 -440 328 -438 180 -454L182 -420C335 -408 498 -410 616 -425C643 -363 676 -300 713 -247C680 -253 609 -261 545 -269L542 -240C606 -234 685 -225 739 -211L762 -241C751 -251 740 -262 733 -274C698 -324 670 -376 647 -429C725 -440 796 -454 842 -468L837 -502C792 -487 712 -468 635 -456C624 -481 615 -505 606 -529C598 -553 587 -584 577 -617C643 -625 724 -642 782 -659L776 -693C716 -670 639 -655 568 -646C555 -692 543 -738 539 -774L501 -767C508 -750 515 -725 521 -703Z",
            "M279 -259L243 -268C223 -228 209 -194 209 -146C209 -33 305 24 493 24C581 24 644 19 713 7V-28C641 -13 582 -6 491 -6C317 -6 240 -58 240 -146C240 -191 255 -224 279 -259Z"
        ]
    },
    {
        "dec": 12367,
        "hex": "304f",
        "char": "く",
        "romanization": "ku",
        "name": "HIRAGANA LETTER KU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M672 -749L638 -779C631 -766 612 -742 600 -730C534 -660 371 -534 299 -473C220 -405 206 -374 294 -302C388 -227 540 -96 619 -16C638 3 656 21 670 37L698 12C591 -99 429 -232 325 -316C251 -376 254 -395 320 -451C398 -516 552 -639 623 -704C633 -714 660 -740 672 -749Z"
        ]
    },
    {
        "dec": 12369,
        "hex": "3051",
        "char": "け",
        "romanization": "ke",
        "name": "HIRAGANA LETTER KE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M196 -746C196 -735 195 -717 192 -699C181 -616 151 -466 151 -308C151 -184 180 -65 198 -4L226 -9C225 -16 223 -26 222 -34C221 -46 222 -62 225 -75C235 -116 267 -217 286 -269L259 -284C241 -222 217 -155 204 -102C158 -302 189 -520 227 -694C231 -711 235 -730 238 -741Z",
            "M699 -754C702 -739 703 -726 705 -705C707 -685 710 -598 712 -534C668 -531 623 -530 578 -530C524 -530 448 -535 410 -541V-507C447 -503 525 -500 577 -500C622 -500 668 -501 713 -504V-481C713 -269 719 -135 585 -32C564 -13 533 5 508 14L540 38C760 -82 744 -260 744 -481V-506C805 -511 863 -519 914 -530V-564C861 -549 803 -541 743 -536C742 -600 739 -685 739 -706C739 -728 740 -743 741 -754Z"
        ]
    },
    {
        "dec": 12371,
        "hex": "3053",
        "char": "こ",
        "romanization": "ko",
        "name": "HIRAGANA LETTER KO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M257 -672V-641C332 -634 414 -630 508 -630C595 -630 688 -638 753 -642V-673C687 -666 600 -660 508 -660C415 -660 326 -663 257 -672Z",
            "M254 -297L222 -301C211 -259 201 -214 201 -169C201 -58 320 -1 494 -1C636 -1 767 -17 822 -36L821 -70C760 -47 633 -32 494 -32C322 -32 232 -87 232 -174C232 -213 239 -255 254 -297Z"
        ]
    },
    {
        "dec": 12373,
        "hex": "3055",
        "char": "さ",
        "romanization": "sa",
        "name": "HIRAGANA LETTER SA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M540 -701C547 -675 558 -637 577 -587C471 -577 318 -579 180 -595L182 -561C321 -550 471 -548 587 -559C622 -466 682 -360 728 -296C687 -301 611 -307 549 -312L547 -282C606 -279 721 -270 760 -258L781 -284C769 -296 757 -308 747 -323C699 -389 647 -479 616 -563C681 -571 775 -587 844 -607L841 -641C772 -614 674 -598 606 -590C585 -650 565 -709 558 -770L521 -765C528 -746 535 -721 540 -701Z",
            "M288 -303L254 -310C232 -261 211 -215 211 -166C211 -42 322 16 493 18C593 19 671 10 737 -1V-38C670 -21 587 -12 494 -13C340 -15 241 -63 241 -163C241 -214 260 -255 288 -303Z"
        ]
    },
    {
        "dec": 12375,
        "hex": "3057",
        "char": "し",
        "romanization": "shi",
        "name": "HIRAGANA LETTER SI = SHI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M314 -757H271C275 -739 276 -716 276 -690C276 -569 265 -329 265 -170C265 -17 356 29 478 29C685 29 799 -87 870 -179L847 -206C778 -109 678 -2 481 -2C371 -2 295 -45 295 -161C295 -332 303 -575 307 -690C308 -716 310 -734 314 -757Z"
        ]
    },
    {
        "dec": 12377,
        "hex": "3059",
        "char": "す",
        "romanization": "su",
        "name": "HIRAGANA LETTER SU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M351 -335C351 -410 408 -462 473 -462C528 -462 574 -431 585 -348C580 -267 543 -214 474 -214C405 -214 351 -256 351 -335Z",
            "M554 -770C555 -766 558 -744 558 -731C559 -711 560 -674 560 -633C396 -631 209 -623 105 -620L106 -585C231 -596 412 -602 561 -604C561 -548 562 -492 562 -463C539 -482 508 -493 471 -493C390 -493 320 -418 320 -335C320 -241 386 -184 474 -184C517 -184 559 -206 583 -249C564 -128 484 -42 318 0L347 26C564 -43 619 -176 619 -313C619 -359 610 -399 591 -430C590 -475 589 -542 589 -604H637C781 -604 858 -603 907 -600V-632C869 -632 775 -634 636 -634H589C589 -674 589 -709 590 -731C590 -740 592 -764 593 -770Z"
        ]
    },
    {
        "dec": 12379,
        "hex": "305b",
        "char": "せ",
        "romanization": "se",
        "name": "HIRAGANA LETTER SE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M710 -752H673C676 -736 677 -712 677 -694C677 -650 677 -581 676 -527C557 -517 425 -505 319 -494C319 -559 320 -620 320 -662C320 -681 321 -706 322 -719H286C290 -694 290 -681 290 -662C290 -649 290 -576 289 -492C226 -485 175 -480 143 -478C122 -475 95 -473 61 -473L65 -434C93 -438 116 -441 146 -445C180 -449 230 -455 289 -461C289 -357 289 -244 290 -202C295 -59 308 -7 508 -7C612 -7 732 -17 798 -24L799 -59C744 -50 625 -39 509 -39C317 -39 323 -89 319 -204C318 -237 318 -351 319 -464C429 -475 563 -488 676 -496C674 -425 669 -331 662 -295C658 -270 650 -267 620 -267C595 -267 553 -272 515 -280L514 -249C533 -245 589 -236 626 -236C665 -236 684 -250 691 -285C702 -336 704 -434 706 -498C762 -501 811 -504 848 -504H907V-542C890 -541 872 -539 848 -538C806 -536 758 -533 707 -529C708 -579 708 -647 708 -694C708 -710 709 -734 710 -752Z"
        ]
    },
    {
        "dec": 12381,
        "hex": "305d",
        "char": "そ",
        "romanization": "so",
        "name": "HIRAGANA LETTER SO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M272 -725L274 -686C286 -687 310 -689 337 -691C381 -694 600 -704 647 -707C583 -652 402 -493 281 -403C232 -397 160 -389 106 -384L109 -350C246 -372 399 -387 518 -398C453 -371 365 -292 365 -186C365 -47 481 28 708 18L715 -16C681 -13 644 -11 583 -19C492 -31 397 -68 397 -188C397 -292 508 -388 609 -403C666 -412 759 -414 857 -410V-444C695 -444 503 -428 336 -409C426 -481 610 -635 686 -698C694 -705 708 -715 714 -719L692 -743C682 -740 667 -738 654 -737C601 -731 379 -722 335 -722C308 -722 289 -723 272 -725Z"
        ]
    },
    {
        "dec": 12383,
        "hex": "305f",
        "char": "た",
        "romanization": "ta",
        "name": "HIRAGANA LETTER TA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M525 -238L493 -241C484 -197 480 -170 480 -141C480 -46 562 -6 705 -6C770 -6 834 -12 885 -20L886 -57C835 -44 769 -37 706 -37C533 -37 510 -94 510 -143C510 -172 515 -202 525 -238Z",
            "M411 -768L371 -773C372 -756 370 -729 367 -710C364 -688 355 -645 345 -596C301 -592 259 -590 225 -590C193 -590 155 -591 113 -597L114 -563C151 -560 184 -559 223 -559C257 -559 296 -561 338 -565C328 -521 318 -475 308 -438C272 -299 207 -106 147 -2L186 11C235 -87 302 -292 337 -433C350 -478 361 -524 370 -568C441 -575 517 -588 586 -603V-637C522 -620 447 -607 376 -599C384 -638 391 -674 397 -706C400 -722 406 -753 411 -768Z",
            "M536 -469V-435C595 -442 655 -446 710 -446C766 -446 821 -441 872 -434L874 -468C825 -474 765 -476 706 -476C644 -476 584 -473 536 -469Z"
        ]
    },
    {
        "dec": 12385,
        "hex": "3061",
        "char": "ち",
        "romanization": "chi",
        "name": "HIRAGANA LETTER TI = CHI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M359 -775C360 -754 358 -733 355 -711C352 -690 348 -660 341 -625C326 -624 312 -624 299 -624C242 -624 170 -631 130 -638L131 -604C186 -599 241 -594 300 -594H335C312 -482 272 -329 218 -225L252 -213C261 -231 271 -246 285 -261C356 -343 472 -383 593 -383C726 -383 794 -320 794 -232C794 -55 572 -5 334 -34L340 0C618 30 825 -44 825 -233C825 -336 745 -412 594 -412C483 -412 379 -382 288 -307C314 -368 345 -495 366 -595C487 -599 643 -614 771 -637V-673C650 -645 493 -630 372 -625C379 -659 384 -688 387 -708C391 -727 396 -755 401 -772Z"
        ]
    },
    {
        "dec": 12387,
        "hex": "3063",
        "char": "っ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL TU = SMALL TSU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M322 -483L338 -446C377 -460 594 -551 718 -551C835 -551 897 -480 897 -401C897 -229 719 -168 515 -160L528 -129C751 -140 928 -214 928 -401C928 -504 846 -581 717 -581C617 -581 469 -526 398 -503C367 -493 348 -488 322 -483Z"
        ]
    },
    {
        "dec": 12388,
        "hex": "3064",
        "char": "つ",
        "romanization": "tsu",
        "name": "HIRAGANA LETTER TU = TSU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M109 -496L125 -457C178 -475 446 -593 624 -593C771 -593 857 -503 857 -389C857 -160 609 -82 362 -74L374 -39C634 -55 890 -141 890 -389C890 -536 771 -623 623 -623C480 -623 295 -552 204 -522C166 -510 144 -503 109 -496Z"
        ]
    },
    {
        "dec": 12390,
        "hex": "3066",
        "char": "て",
        "romanization": "te",
        "name": "HIRAGANA LETTER TE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M95 -634L100 -594C199 -614 494 -643 614 -656C501 -599 384 -461 384 -296C384 -70 613 14 772 15L783 -19C630 -22 416 -83 416 -304C416 -419 508 -590 669 -649C716 -666 811 -667 870 -667V-700C809 -698 722 -693 609 -683C457 -670 219 -645 174 -639C154 -637 131 -635 95 -634Z"
        ]
    },
    {
        "dec": 12392,
        "hex": "3068",
        "char": "と",
        "romanization": "to",
        "name": "HIRAGANA LETTER TO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M240 -739C288 -629 344 -502 388 -428L379 -422C264 -346 211 -266 211 -178C211 -52 332 3 504 3C622 3 745 -8 809 -20V-58C743 -40 614 -28 501 -28C328 -28 242 -89 242 -181C242 -261 296 -332 396 -398C498 -466 648 -539 720 -577C742 -589 759 -597 773 -605L755 -636C739 -623 726 -615 705 -603C644 -569 516 -509 414 -445C370 -522 314 -641 274 -753Z"
        ]
    },
    {
        "dec": 12394,
        "hex": "306a",
        "char": "な",
        "romanization": "na",
        "name": "HIRAGANA LETTER NA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M434 -775L393 -779C390 -725 374 -656 357 -604C310 -599 268 -598 229 -598C181 -598 149 -600 119 -603L121 -569C152 -568 194 -567 228 -567C267 -567 307 -569 346 -572C302 -447 213 -278 129 -185L163 -167C240 -269 331 -437 381 -576C444 -584 506 -597 566 -613L565 -648C503 -627 445 -615 391 -608C407 -663 423 -736 434 -775Z",
            "M376 -93C376 -149 436 -190 523 -190C566 -190 606 -183 644 -171C645 -145 645 -121 645 -101C645 -50 614 -3 518 -3C419 -3 376 -38 376 -93Z",
            "M667 -475H634C635 -395 640 -289 643 -201C605 -212 564 -218 521 -218C437 -218 346 -177 346 -92C346 -4 426 28 520 28C625 28 675 -28 675 -94C675 -113 675 -136 674 -161C747 -133 808 -87 857 -45L877 -74C825 -116 756 -163 673 -192C670 -263 666 -344 665 -390C664 -417 665 -436 667 -475Z",
            "M888 -475L907 -502C865 -536 763 -598 691 -629L676 -605C738 -576 839 -519 888 -475Z"
        ]
    },
    {
        "dec": 12395,
        "hex": "306b",
        "char": "に",
        "romanization": "ni",
        "name": "HIRAGANA LETTER NI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M465 -648V-614C557 -602 759 -602 849 -614V-648C759 -632 558 -629 465 -648Z",
            "M465 -267L433 -271C423 -226 418 -194 418 -168C418 -84 485 -34 644 -34C737 -34 822 -42 881 -55L880 -90C807 -73 728 -65 640 -65C473 -65 448 -124 448 -170C448 -197 454 -229 465 -267Z",
            "M243 -733L204 -736C204 -725 203 -712 200 -693C188 -607 152 -436 152 -296C152 -164 167 -58 187 14L216 12C215 5 213 -6 212 -16C211 -29 214 -44 217 -58C225 -99 264 -200 286 -257L263 -274C244 -227 214 -145 195 -90C187 -147 182 -222 182 -296C182 -419 208 -573 232 -689C236 -707 240 -720 243 -733Z"
        ]
    },
    {
        "dec": 12396,
        "hex": "306c",
        "char": "ぬ",
        "romanization": "nu",
        "name": "HIRAGANA LETTER NU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M531 -123C531 -162 567 -205 644 -205C703 -205 755 -183 798 -153C770 -85 720 -40 639 -40C571 -40 531 -73 531 -123Z",
            "M565 -761L525 -762C528 -735 527 -712 525 -688C523 -667 521 -645 518 -620C404 -604 315 -551 251 -491C229 -549 208 -613 195 -668L162 -658C172 -636 183 -604 194 -568C203 -541 215 -505 229 -468C210 -447 193 -426 180 -406C135 -342 104 -264 104 -173C104 -96 148 -41 207 -41C328 -41 425 -187 492 -365C521 -444 534 -520 544 -595C557 -596 571 -597 585 -597C751 -597 831 -472 831 -354C831 -293 825 -234 809 -184C763 -212 708 -233 644 -233C563 -233 502 -185 502 -122C502 -56 554 -10 639 -10C727 -10 788 -55 824 -135C863 -103 894 -67 917 -39L941 -68C916 -96 881 -134 836 -166C853 -218 862 -282 862 -355C862 -530 726 -625 586 -625C573 -625 560 -624 548 -624C550 -647 553 -670 556 -693C559 -714 562 -746 565 -761Z",
            "M134 -174C134 -251 154 -312 202 -388C214 -406 226 -423 240 -439C257 -395 275 -352 288 -323C306 -282 339 -225 369 -186C320 -115 264 -71 206 -71C172 -71 134 -110 134 -174Z",
            "M313 -339C301 -367 282 -411 262 -462C329 -530 415 -576 514 -592C504 -520 489 -442 467 -380C443 -317 416 -260 387 -213C359 -250 330 -301 313 -339Z"
        ]
    },
    {
        "dec": 12397,
        "hex": "306d",
        "char": "ね",
        "romanization": "ne",
        "name": "HIRAGANA LETTER NE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M516 -134C516 -186 572 -222 632 -222C696 -222 749 -206 799 -176C777 -95 731 -42 639 -42C572 -42 516 -72 516 -134Z",
            "M315 -712C315 -701 312 -658 308 -603C252 -593 174 -585 143 -582C131 -581 116 -580 100 -581L104 -544C172 -554 265 -566 306 -572C304 -534 301 -491 298 -449C255 -377 130 -203 76 -135L98 -106C161 -194 244 -309 294 -388C291 -329 289 -275 289 -237V-26C289 -10 288 10 287 17H324C323 5 322 -10 321 -27C319 -91 319 -183 319 -236C319 -284 321 -344 325 -406C416 -501 568 -599 660 -599C750 -599 818 -514 818 -373C818 -313 815 -256 806 -207C751 -237 697 -250 635 -250C553 -250 487 -202 487 -132C487 -45 563 -13 641 -13C740 -13 797 -63 825 -158C860 -133 893 -100 929 -60L951 -90C910 -133 871 -166 833 -190C844 -238 848 -295 848 -360C848 -504 789 -627 665 -627C550 -627 412 -523 327 -447C329 -474 330 -501 332 -528C346 -550 361 -573 373 -592L352 -612C348 -611 344 -610 339 -609C346 -687 353 -748 357 -768L313 -770C315 -752 315 -729 315 -712Z"
        ]
    },
    {
        "dec": 12398,
        "hex": "306e",
        "char": "の",
        "romanization": "no",
        "name": "HIRAGANA LETTER NO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M884 -360C884 -543 744 -699 527 -699C302 -699 120 -521 120 -321C120 -162 205 -81 271 -81C345 -81 418 -168 478 -373C505 -462 524 -570 536 -670C738 -666 853 -518 853 -362C853 -167 706 -71 579 -44C560 -39 527 -35 503 -34L520 -3C739 -25 884 -155 884 -360Z",
            "M149 -322C149 -480 294 -658 505 -669C495 -573 475 -471 448 -380C389 -182 323 -114 273 -114C223 -114 149 -176 149 -322Z"
        ]
    },
    {
        "dec": 12399,
        "hex": "306f",
        "char": "は",
        "romanization": "ha",
        "name": "HIRAGANA LETTER HA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M422 -115C422 -173 485 -213 576 -213C620 -213 660 -206 698 -193C699 -173 699 -156 699 -143C699 -70 672 -21 567 -21C478 -21 422 -54 422 -115Z",
            "M722 -750H683C685 -735 686 -713 686 -694C685 -660 685 -611 685 -560C647 -558 608 -557 569 -557C512 -557 463 -560 409 -565V-533C465 -529 511 -526 566 -526C606 -526 645 -527 685 -529C685 -442 693 -315 697 -224C660 -235 621 -241 579 -241C456 -241 392 -178 392 -113C392 -40 454 10 580 10C704 10 728 -70 728 -130C728 -146 728 -164 727 -182C793 -154 850 -109 906 -58L925 -89C869 -138 803 -186 726 -215C722 -315 714 -437 714 -531C778 -535 840 -542 900 -552V-584C844 -573 780 -566 714 -561C714 -606 714 -662 716 -695C717 -713 719 -730 722 -750Z",
            "M233 -747L195 -751C195 -740 194 -726 191 -707C178 -621 142 -432 142 -292C142 -159 158 -56 177 16L207 13C206 7 204 -5 203 -14C202 -27 204 -43 207 -57C215 -99 254 -199 277 -255L254 -273C234 -224 204 -143 186 -88C175 -164 171 -217 171 -293C171 -414 198 -587 222 -703C225 -721 229 -735 233 -747Z"
        ]
    },
    {
        "dec": 12402,
        "hex": "3072",
        "char": "ひ",
        "romanization": "hi",
        "name": "HIRAGANA LETTER HI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M718 -717L684 -707C692 -676 701 -647 709 -619C760 -250 665 -11 430 -11C323 -11 216 -57 216 -219C216 -433 384 -624 441 -666C455 -671 483 -676 495 -679L486 -711C435 -690 268 -665 187 -662C169 -661 149 -661 137 -662L140 -623C157 -624 175 -627 192 -629C234 -635 331 -647 393 -660C311 -580 187 -420 187 -214C187 -66 277 21 429 21C698 21 772 -247 743 -523C781 -427 829 -348 899 -276L924 -302C784 -425 740 -602 718 -717Z"
        ]
    },
    {
        "dec": 12405,
        "hex": "3075",
        "char": "ふ",
        "romanization": "fu",
        "name": "HIRAGANA LETTER HU = FU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M536 -533L559 -510C592 -534 628 -561 648 -579L640 -604C590 -648 478 -711 408 -744L389 -719C465 -685 568 -627 611 -587C595 -573 565 -550 536 -533Z",
            "M327 -49L330 -13C371 -5 421 3 491 3C535 3 635 -13 635 -123C635 -209 564 -295 485 -382C465 -405 440 -434 422 -454L398 -434C421 -411 447 -385 466 -363C530 -287 604 -199 604 -126C604 -46 531 -28 491 -28C416 -28 373 -36 327 -49Z",
            "M913 -49L942 -65C911 -146 839 -279 766 -361L741 -346C811 -276 881 -138 913 -49Z",
            "M333 -245L315 -268C262 -209 140 -116 63 -75L82 -49C169 -98 275 -185 333 -245Z"
        ]
    },
    {
        "dec": 12408,
        "hex": "3078",
        "char": "へ",
        "romanization": "he",
        "name": "HIRAGANA LETTER HE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M81 -256L111 -228C125 -244 147 -267 165 -288C222 -354 313 -472 366 -536C405 -583 421 -592 470 -538C515 -489 591 -394 658 -321C729 -242 825 -137 903 -63L929 -91C845 -165 734 -282 679 -344C611 -415 538 -509 487 -563C430 -626 389 -614 343 -558C287 -490 199 -367 140 -310C118 -287 101 -269 81 -256Z"
        ]
    },
    {
        "dec": 12411,
        "hex": "307b",
        "char": "ほ",
        "romanization": "ho",
        "name": "HIRAGANA LETTER HO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M429 -121C429 -173 489 -208 574 -208C611 -208 647 -203 683 -193C684 -169 685 -149 685 -136C685 -78 669 -30 574 -30C485 -30 429 -59 429 -121Z",
            "M437 -690V-658C506 -654 591 -656 671 -659V-461C590 -458 504 -458 421 -464V-432C502 -427 589 -427 671 -431C672 -368 677 -288 681 -225C648 -233 612 -237 574 -237C447 -237 400 -173 400 -121C400 -42 469 0 572 0C656 0 713 -37 713 -109C713 -128 712 -154 711 -185C782 -160 848 -116 905 -57L924 -90C865 -143 795 -192 709 -217C706 -285 702 -366 701 -432C775 -435 844 -441 899 -446V-479C842 -472 774 -466 701 -462V-660C768 -664 830 -668 875 -673V-704C766 -690 573 -679 437 -690Z",
            "M235 -739L197 -742C197 -732 196 -718 193 -699C180 -613 144 -434 144 -293C144 -161 159 -58 179 14L209 11C208 5 206 -7 205 -16C204 -29 207 -44 210 -58C218 -100 257 -203 278 -257L256 -276C236 -229 206 -143 188 -89C178 -165 174 -219 174 -294C174 -416 200 -579 224 -695C227 -713 231 -726 235 -739Z"
        ]
    },
    {
        "dec": 12414,
        "hex": "307e",
        "char": "ま",
        "romanization": "ma",
        "name": "HIRAGANA LETTER MA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M235 -104C235 -160 297 -206 415 -206C452 -206 488 -202 522 -194C523 -160 524 -128 524 -104C524 -26 464 -7 403 -7C277 -7 235 -50 235 -104Z",
            "M513 -772C514 -764 515 -737 515 -721V-632C497 -631 480 -631 463 -631C393 -631 261 -641 204 -652V-617C263 -610 391 -600 464 -600C481 -600 498 -600 515 -601V-476C515 -463 515 -448 516 -430C495 -429 476 -429 457 -429C380 -429 276 -436 200 -447V-413C276 -405 381 -399 457 -399C476 -399 496 -399 516 -400C518 -347 520 -283 522 -225C488 -231 454 -235 418 -235C286 -235 205 -183 205 -103C205 -18 278 23 403 23C530 23 557 -49 557 -104C557 -121 556 -150 554 -186C672 -153 768 -83 829 -29L850 -61C796 -104 688 -183 553 -218C550 -271 547 -335 546 -401C634 -404 732 -412 829 -426V-459C731 -443 632 -434 545 -431V-602C636 -606 732 -617 825 -626V-660C739 -645 639 -636 545 -633V-710C546 -743 547 -757 549 -772Z"
        ]
    },
    {
        "dec": 12415,
        "hex": "307f",
        "char": "み",
        "romanization": "mi",
        "name": "HIRAGANA LETTER MI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M239 -705L241 -668C262 -670 284 -672 306 -673C351 -675 502 -683 541 -685C504 -609 460 -508 418 -416C231 -416 102 -311 102 -172C102 -109 146 -61 206 -61C241 -61 272 -72 303 -113C343 -168 397 -302 436 -388C555 -384 669 -343 763 -291C730 -165 658 -48 499 17L525 41C677 -32 753 -130 790 -276C837 -248 879 -217 913 -189L930 -223C894 -250 849 -280 798 -309C810 -367 816 -432 820 -506L786 -509C787 -483 786 -456 785 -435C782 -398 778 -360 770 -323C678 -370 567 -410 448 -415C495 -514 546 -632 575 -677C583 -689 588 -696 594 -702L572 -720C563 -717 554 -716 541 -715C504 -712 351 -703 304 -703C285 -703 262 -703 239 -705Z",
            "M131 -174C131 -281 231 -384 405 -388C366 -300 318 -180 273 -126C250 -99 232 -92 206 -92C168 -92 131 -119 131 -174Z"
        ]
    },
    {
        "dec": 12416,
        "hex": "3080",
        "char": "む",
        "romanization": "mu",
        "name": "HIRAGANA LETTER MU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M251 -177C207 -177 170 -212 170 -279C170 -369 220 -433 278 -433C319 -433 345 -399 345 -343C345 -267 323 -177 251 -177Z",
            "M695 -640C747 -602 834 -523 876 -465L899 -489C854 -546 770 -622 716 -661Z",
            "M321 -769C324 -755 325 -722 325 -706C325 -688 326 -655 326 -617C309 -616 292 -615 276 -615C224 -615 186 -618 124 -628V-596C174 -588 233 -585 277 -585C293 -585 309 -585 326 -586C326 -534 327 -480 326 -450C313 -458 297 -463 280 -463C192 -463 138 -365 138 -281C138 -178 208 -146 247 -146C266 -146 282 -150 295 -156C293 -130 292 -105 292 -78C292 0 339 21 505 21C568 21 657 12 698 3C764 -10 806 -41 809 -137C810 -177 809 -205 808 -233L774 -246C778 -211 779 -183 779 -144C779 -65 739 -42 688 -27C655 -18 567 -10 506 -10C337 -10 322 -29 322 -95C322 -117 324 -152 326 -178C369 -222 377 -302 377 -346C377 -375 369 -400 356 -420V-588C424 -594 494 -606 556 -620V-655C494 -639 423 -625 356 -619V-706C356 -719 358 -755 360 -769Z"
        ]
    },
    {
        "dec": 12417,
        "hex": "3081",
        "char": "め",
        "romanization": "me",
        "name": "HIRAGANA LETTER ME",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M567 -581C532 -457 480 -339 439 -271C435 -265 431 -258 427 -251C411 -275 397 -299 384 -322C362 -362 331 -434 306 -502C387 -558 474 -581 557 -581Z",
            "M142 -216C142 -307 199 -416 282 -483C308 -412 337 -343 360 -303C376 -274 392 -247 408 -223C360 -155 294 -88 232 -88C178 -88 142 -135 142 -216Z",
            "M601 -760C601 -738 598 -715 596 -704C590 -673 583 -641 575 -609H561C470 -609 381 -586 296 -530C284 -564 274 -597 267 -622C260 -648 252 -681 248 -701L212 -690C219 -674 230 -641 238 -613C247 -582 259 -548 271 -512C183 -446 113 -328 113 -216C113 -113 164 -57 231 -57C304 -57 371 -122 426 -196C446 -168 466 -144 485 -123L511 -146C488 -168 466 -195 446 -224C451 -231 456 -239 461 -247C506 -321 560 -454 597 -579C748 -562 847 -451 847 -307C847 -128 706 -25 512 -7L528 22C744 -5 877 -124 877 -307C877 -471 764 -589 605 -607C614 -640 621 -672 627 -702C630 -716 634 -736 639 -757Z"
        ]
    },
    {
        "dec": 12418,
        "hex": "3082",
        "char": "も",
        "romanization": "mo",
        "name": "HIRAGANA LETTER MO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M379 -766C381 -752 381 -737 379 -714C376 -680 371 -637 365 -589C294 -592 205 -604 139 -625L136 -593C202 -576 291 -564 361 -559C353 -489 343 -411 335 -342C262 -345 177 -357 112 -381L110 -347C179 -325 260 -314 332 -310C326 -258 323 -214 323 -183C323 -27 430 24 541 24C714 24 843 -47 843 -192C843 -276 806 -344 737 -410L700 -403C779 -342 812 -259 812 -194C812 -72 700 -7 539 -7C411 -7 354 -77 354 -186C354 -216 357 -259 362 -309C373 -308 384 -308 395 -308C465 -308 529 -310 605 -318V-352C532 -341 468 -340 394 -340H365C373 -409 383 -487 392 -558H407C484 -558 545 -561 612 -567V-599C547 -591 481 -588 406 -588H396C402 -636 407 -679 411 -713C413 -731 416 -747 419 -763Z"
        ]
    },
    {
        "dec": 12419,
        "hex": "3083",
        "char": "ゃ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL YA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M695 -558L718 -580C688 -611 627 -660 602 -678L580 -660C615 -634 666 -589 695 -558Z",
            "M424 -617L388 -601C397 -591 405 -579 417 -561C431 -538 452 -500 472 -458C418 -433 371 -411 345 -401C331 -396 326 -394 307 -390L327 -355C364 -372 421 -402 485 -431C499 -402 513 -373 524 -346C571 -238 605 -123 628 -39L664 -50C637 -133 601 -250 553 -357C541 -384 527 -414 512 -444C607 -487 711 -526 785 -526C881 -526 910 -470 910 -421C910 -388 891 -315 784 -315C735 -315 689 -328 663 -341L661 -308C688 -297 735 -285 782 -285C903 -285 941 -359 941 -420C941 -499 888 -555 785 -555C708 -555 595 -511 500 -470C478 -515 457 -556 444 -578C436 -592 429 -606 424 -617Z"
        ]
    },
    {
        "dec": 12420,
        "hex": "3084",
        "char": "や",
        "romanization": "ya",
        "name": "HIRAGANA LETTER YA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M580 -627L601 -650C562 -691 486 -751 456 -774L433 -754C478 -721 539 -667 580 -627Z",
            "M226 -694L190 -678C203 -663 218 -639 228 -623C244 -598 269 -552 297 -494C236 -466 184 -442 153 -430C134 -422 108 -413 89 -408L108 -371C156 -392 229 -429 310 -467C325 -436 342 -401 358 -366C418 -227 462 -78 493 31L530 20C496 -86 448 -242 387 -378C371 -412 354 -447 338 -480C459 -535 593 -588 689 -588C807 -588 854 -523 854 -456C854 -387 820 -309 680 -309C618 -309 569 -325 537 -341L536 -306C572 -293 622 -279 678 -279C837 -279 885 -365 885 -454C885 -554 811 -617 690 -617C586 -617 442 -559 325 -507C300 -557 276 -603 257 -638C247 -655 233 -680 226 -694Z"
        ]
    },
    {
        "dec": -1,
        "hex": "3084",
        "char": null,
        "name": "na",
        "romanization": "yi"
    },
    {
        "dec": 12421,
        "hex": "3085",
        "char": "ゅ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL YU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M723 -541C842 -541 910 -471 910 -375C910 -278 850 -188 715 -188C706 -188 698 -188 689 -189C711 -251 720 -324 720 -409C720 -453 717 -498 713 -541Z",
            "M656 -677C660 -669 664 -653 667 -639C670 -625 675 -601 680 -566C560 -550 438 -463 395 -322C390 -393 406 -528 419 -592C421 -602 423 -610 425 -616L388 -620C388 -612 388 -604 387 -593C383 -553 364 -442 364 -353C364 -281 378 -206 390 -163L417 -169C412 -188 408 -219 406 -240C406 -262 408 -285 413 -299C451 -415 551 -520 683 -538C686 -503 689 -459 689 -408C689 -323 681 -252 659 -195C600 -210 558 -252 531 -313L509 -292C540 -228 588 -184 647 -166C623 -117 586 -79 533 -53L563 -33C616 -65 652 -107 677 -159C688 -158 699 -157 710 -157C878 -157 941 -268 941 -378C941 -487 857 -569 721 -569H710C704 -614 698 -654 692 -679Z"
        ]
    },
    {
        "dec": 12422,
        "hex": "3086",
        "char": "ゆ",
        "romanization": "yu",
        "name": "HIRAGANA LETTER YU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M534 -770C539 -757 545 -737 548 -719C552 -703 558 -671 563 -629C391 -609 239 -471 191 -296C182 -386 206 -584 220 -661C223 -677 225 -687 227 -693L189 -696C190 -686 190 -678 189 -664C184 -614 162 -467 162 -354C162 -264 176 -173 191 -118L219 -124C213 -147 206 -187 204 -212C202 -238 208 -269 213 -286C254 -432 385 -580 566 -601C572 -551 576 -491 576 -426C576 -316 563 -226 534 -154C457 -173 401 -226 364 -309L338 -287C380 -204 442 -147 521 -126C489 -61 442 -11 376 24L403 43C472 3 520 -51 552 -119C567 -117 582 -116 597 -116C792 -116 884 -237 884 -389C884 -522 785 -632 607 -632H592C585 -690 577 -739 569 -772Z",
            "M609 -603C772 -603 854 -504 854 -386C854 -233 748 -146 602 -146C589 -146 577 -147 564 -148C593 -225 605 -318 605 -427C605 -487 601 -548 596 -603Z"
        ]
    },
    {
        "dec": -1,
        "hex": "3084",
        "char": null,
        "name": "na",
        "romanization": "ye"
    },
    {
        "dec": 12423,
        "hex": "3087",
        "char": "ょ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL YO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M387 -194C387 -246 446 -278 540 -278C567 -278 595 -275 622 -269C623 -239 624 -213 624 -195C624 -151 593 -115 529 -115C432 -115 387 -148 387 -194Z",
            "M650 -715H609C613 -706 615 -671 615 -652V-484C615 -434 618 -362 621 -300C595 -304 568 -307 541 -307C414 -307 356 -258 356 -194C356 -125 418 -86 531 -86C613 -86 655 -135 655 -187C655 -207 654 -233 653 -262C742 -237 826 -186 882 -129L900 -162C841 -213 754 -268 652 -293C649 -359 645 -432 645 -484V-523C712 -524 817 -529 888 -537V-567C815 -557 710 -553 645 -552V-652C645 -670 647 -705 650 -715Z"
        ]
    },
    {
        "dec": 12424,
        "hex": "3088",
        "char": "よ",
        "romanization": "yo",
        "name": "HIRAGANA LETTER YO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M198 -108C198 -174 266 -217 392 -217C429 -217 466 -213 502 -205C503 -169 504 -137 504 -115C504 -45 458 -7 378 -7C257 -7 198 -47 198 -108Z",
            "M528 -762H486C489 -749 491 -704 491 -682C491 -640 492 -537 492 -483C492 -416 497 -320 500 -238C465 -244 430 -248 393 -248C233 -248 167 -183 167 -108C167 -17 252 24 382 24C481 24 534 -33 534 -105C534 -130 533 -162 532 -198C647 -167 752 -102 822 -31L841 -65C767 -131 657 -200 530 -231C527 -317 522 -415 522 -482V-524C605 -525 737 -533 829 -543L828 -575C734 -562 603 -556 522 -555V-682C522 -702 525 -748 528 -762Z"
        ]
    },
    {
        "dec": 12425,
        "hex": "3089",
        "char": "ら",
        "romanization": "ra",
        "name": "HIRAGANA LETTER RA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M340 -757L331 -724C404 -703 611 -662 698 -650L706 -682C619 -690 415 -733 340 -757Z",
            "M291 -596L256 -599C250 -516 225 -296 205 -214L240 -206C243 -218 250 -235 262 -247C338 -339 449 -394 596 -394C710 -394 794 -328 794 -237C794 -94 648 11 325 -23L334 11C656 37 825 -64 825 -235C825 -347 724 -422 595 -422C463 -422 346 -378 247 -276C260 -346 278 -517 291 -596Z"
        ]
    },
    {
        "dec": 12426,
        "hex": "308a",
        "char": "り",
        "romanization": "ri",
        "name": "HIRAGANA LETTER RI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M327 -768L287 -769C284 -742 283 -725 280 -702C271 -645 246 -477 246 -383C246 -321 251 -271 255 -235L288 -238C281 -291 281 -327 288 -375C308 -503 433 -685 562 -685C686 -685 740 -547 740 -388C740 -133 561 -34 353 -2L372 26C597 -14 770 -123 770 -390C770 -586 688 -714 565 -714C426 -714 310 -552 277 -427C283 -509 300 -673 327 -768Z"
        ]
    },
    {
        "dec": 12427,
        "hex": "308b",
        "char": "る",
        "romanization": "ru",
        "name": "HIRAGANA LETTER RU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M344 -98C344 -141 387 -174 440 -174C535 -174 593 -108 605 -18C574 -11 541 -8 505 -8C410 -8 344 -39 344 -98Z",
            "M258 -706L259 -669C279 -671 293 -672 310 -673C360 -674 612 -686 666 -689C615 -644 473 -523 418 -477C362 -429 230 -320 138 -241L163 -218C307 -352 385 -409 560 -409C702 -409 796 -326 796 -222C796 -121 733 -55 636 -26C623 -114 563 -201 441 -201C363 -201 315 -152 315 -97C315 -32 375 23 506 23C685 23 827 -61 827 -222C827 -344 719 -437 560 -437C503 -437 439 -427 382 -407C472 -483 635 -625 679 -662C694 -675 709 -686 722 -696L695 -725C686 -722 681 -721 663 -719C615 -714 358 -704 308 -704C297 -704 275 -704 258 -706Z"
        ]
    },
    {
        "dec": 12428,
        "hex": "308c",
        "char": "れ",
        "romanization": "re",
        "name": "HIRAGANA LETTER RE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M315 -712C315 -701 312 -658 309 -602C253 -592 176 -584 143 -582C130 -581 115 -580 99 -581L103 -545C171 -555 266 -567 307 -573C304 -535 301 -491 299 -449C256 -378 129 -203 74 -135L97 -106C161 -196 245 -313 295 -392C292 -329 289 -275 289 -247C289 -153 288 -120 288 -26C288 -10 287 3 286 17H324C322 2 321 -10 320 -27C317 -110 318 -148 318 -246C318 -289 320 -342 323 -396C425 -508 553 -596 640 -596C697 -596 730 -572 730 -506C730 -405 692 -227 692 -115C692 -49 731 -15 788 -15C841 -15 904 -43 963 -105L954 -139C898 -78 842 -47 785 -47C740 -47 722 -82 722 -121C722 -218 762 -411 762 -506C762 -583 719 -625 642 -625C540 -625 397 -512 325 -439C327 -471 329 -502 331 -533C344 -554 358 -577 370 -595L351 -611C347 -610 342 -609 337 -607C344 -686 352 -748 356 -768L312 -770C315 -752 315 -729 315 -712Z"
        ]
    },
    {
        "dec": 12429,
        "hex": "308d",
        "char": "ろ",
        "romanization": "ro",
        "name": "HIRAGANA LETTER RO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M252 -699L253 -661C271 -663 291 -665 309 -666C360 -669 587 -676 654 -680C564 -591 291 -359 136 -235L164 -209C304 -335 377 -411 562 -411C703 -411 791 -340 791 -229C791 -69 598 17 325 -15L334 20C622 43 822 -47 822 -227C822 -357 716 -438 564 -438C510 -438 453 -429 378 -401C456 -468 596 -584 684 -664C691 -670 708 -682 716 -686L687 -716C676 -712 658 -710 648 -709C589 -705 359 -697 308 -697C288 -697 270 -697 252 -699Z"
        ]
    },
    {
        "dec": 12430,
        "hex": "308e",
        "char": "ゎ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL WA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M470 -621C469 -604 467 -572 465 -535C419 -527 357 -521 334 -520C324 -519 311 -519 302 -519L307 -482C358 -490 430 -501 463 -506C461 -473 459 -439 457 -410C421 -352 325 -221 282 -166L306 -138C354 -206 413 -293 454 -353C449 -258 450 -143 450 -78C450 -66 449 -49 448 -43H484C483 -55 482 -66 482 -79C480 -152 480 -221 481 -299C481 -321 482 -344 483 -368C555 -435 648 -487 738 -487C868 -487 911 -393 911 -330C911 -203 799 -126 653 -108L665 -78C835 -106 942 -190 942 -329C942 -448 848 -516 740 -516C666 -516 572 -481 485 -403C486 -422 488 -441 489 -460C501 -484 514 -511 523 -526L504 -545C502 -544 499 -543 497 -543C503 -603 510 -651 514 -667L470 -668C471 -654 471 -634 470 -621Z"
        ]
    },
    {
        "dec": 12431,
        "hex": "308f",
        "char": "わ",
        "romanization": "wa",
        "name": "HIRAGANA LETTER WA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M315 -712C315 -701 312 -658 309 -602C253 -592 176 -584 143 -582C130 -581 115 -580 100 -581L104 -545C171 -555 266 -567 307 -573C304 -534 301 -490 299 -448C254 -376 129 -203 75 -135L98 -106C161 -195 245 -312 295 -391C292 -329 289 -275 289 -247C289 -153 288 -120 288 -26C288 -10 287 10 286 17H324C322 2 321 -10 320 -27C317 -110 318 -148 318 -246C318 -288 320 -339 323 -393C416 -485 534 -550 657 -550C817 -550 874 -422 874 -348C876 -163 704 -80 544 -58L558 -27C743 -61 907 -151 905 -347C904 -494 781 -579 660 -579C562 -579 441 -537 325 -432C327 -466 329 -500 332 -533C345 -554 359 -577 370 -595L351 -611C347 -610 343 -609 338 -608C346 -686 353 -748 358 -768L313 -770C315 -752 315 -729 315 -712Z"
        ]
    },
    {
        "dec": 12432,
        "hex": "3090",
        "char": "ゐ",
        "romanization": "wi",
        "name": "HIRAGANA LETTER WI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M452 -95C452 -138 493 -168 545 -168C626 -168 673 -110 697 -34C662 -19 621 -11 575 -11C507 -11 452 -39 452 -95Z",
            "M224 -719L226 -680C312 -689 456 -696 514 -700C504 -657 487 -596 466 -528C269 -479 117 -301 117 -140C117 -66 160 -32 206 -32C242 -32 269 -41 297 -72C335 -112 384 -196 423 -306C447 -373 469 -441 488 -503C513 -508 540 -511 567 -511C741 -511 830 -390 830 -252C830 -161 793 -90 727 -50C704 -118 651 -197 543 -197C478 -197 421 -157 421 -94C421 -28 478 19 576 19C782 19 863 -119 863 -254C863 -410 758 -541 571 -541C546 -541 521 -539 497 -535C517 -598 532 -653 543 -690C546 -701 554 -711 559 -718L527 -737C515 -732 503 -729 482 -727C425 -723 317 -718 281 -717C263 -716 237 -718 224 -719Z",
            "M147 -141C147 -275 275 -444 456 -495C437 -437 415 -375 394 -318C361 -229 321 -140 275 -92C250 -69 227 -64 206 -64C176 -64 147 -88 147 -141Z"
        ]
    },
    {
        "dec": -1,
        "hex": "3084",
        "char": null,
        "name": "na",
        "romanization": "wu"
    },
    {
        "dec": 12433,
        "hex": "3091",
        "char": "ゑ",
        "romanization": "we",
        "name": "HIRAGANA LETTER WE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M263 -696C329 -702 571 -708 609 -709C487 -640 262 -508 156 -431L178 -405C263 -467 325 -509 382 -533C435 -555 510 -563 564 -563C664 -563 755 -517 755 -432C755 -341 636 -288 515 -288H502C540 -308 567 -335 567 -369C567 -424 514 -454 445 -454C385 -454 314 -422 314 -364C314 -325 338 -294 394 -276C306 -216 174 -102 80 2L112 22C176 -58 248 -141 324 -141C391 -141 437 -99 464 -21C468 -10 469 9 470 17H500C501 8 503 -9 508 -22C536 -100 608 -156 675 -156C756 -156 825 -97 873 25L905 11C855 -103 777 -186 677 -186C594 -186 519 -120 486 -42C459 -122 393 -181 293 -162C323 -189 386 -238 430 -267C454 -263 482 -261 514 -261C658 -261 785 -322 785 -433C785 -541 675 -589 567 -589C523 -589 476 -583 431 -572C513 -620 649 -703 691 -720L668 -747C660 -743 634 -739 607 -738C561 -737 328 -730 308 -730C292 -730 278 -731 261 -733Z",
            "M344 -364C344 -407 399 -426 443 -426C493 -426 536 -407 536 -369C536 -337 492 -307 449 -292C374 -302 344 -330 344 -364Z"
        ]
    },
    {
        "dec": 12434,
        "hex": "3092",
        "char": "を",
        "romanization": "wo",
        "name": "HIRAGANA LETTER WO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M413 -774C410 -738 400 -690 383 -643C355 -642 328 -642 302 -642C262 -642 198 -645 143 -652V-617C198 -614 256 -612 300 -612C323 -612 346 -612 371 -613C344 -552 286 -435 134 -283L166 -262C198 -299 226 -335 253 -359C305 -405 367 -435 437 -435C498 -435 538 -403 539 -351V-346C537 -344 534 -343 532 -342C417 -281 313 -213 313 -111C313 0 422 24 551 24C628 24 726 17 810 7L811 -27C725 -14 624 -7 554 -7C447 -7 344 -17 344 -113C344 -195 431 -257 537 -312L539 -313C540 -257 539 -173 537 -127H572C571 -178 570 -273 570 -329C656 -373 742 -405 808 -432C827 -440 845 -448 865 -453L848 -488C830 -478 817 -472 796 -462C734 -433 652 -402 570 -361C565 -425 510 -463 442 -463C391 -463 331 -444 282 -410C331 -470 375 -545 403 -614C511 -617 632 -626 733 -643V-675C635 -658 521 -648 414 -644C432 -694 439 -736 447 -771Z"
        ]
    },
    {
        "dec": 12364,
        "hex": "304c",
        "char": "が",
        "romanization": "ga",
        "name": "HIRAGANA LETTER GA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M735 -636L706 -620C774 -545 862 -359 893 -256L924 -271C889 -371 797 -562 735 -636Z",
            "M773 -785L746 -773C773 -736 809 -676 828 -636L856 -650C834 -693 798 -751 773 -785Z",
            "M873 -820L847 -807C876 -771 908 -716 931 -672L959 -685C939 -724 899 -784 873 -820Z",
            "M398 -764L359 -767C358 -697 345 -614 330 -549C261 -543 192 -537 158 -536C131 -535 114 -535 87 -536L92 -497C113 -500 143 -503 160 -504C192 -506 257 -513 323 -519C294 -398 213 -144 109 -5L142 8C259 -179 320 -385 355 -522C412 -527 466 -531 497 -531C561 -531 609 -511 609 -410C609 -295 591 -158 556 -81C531 -29 498 -24 459 -24C429 -24 382 -29 338 -44L343 -7C372 -1 419 7 456 7C509 7 552 -4 581 -66C622 -145 639 -298 639 -415C639 -539 570 -560 498 -560C472 -560 419 -556 362 -551C376 -621 387 -705 391 -729C393 -743 395 -754 398 -764Z"
        ]
    },
    {
        "dec": 12366,
        "hex": "304e",
        "char": "ぎ",
        "romanization": "gi",
        "name": "HIRAGANA LETTER GI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M775 -779L748 -767C777 -728 811 -668 831 -625L858 -640C835 -688 797 -749 775 -779Z",
            "M873 -814L847 -801C877 -763 911 -703 932 -662L959 -675C935 -722 896 -784 873 -814Z",
            "M273 -257L237 -266C217 -226 203 -192 203 -144C203 -31 299 26 487 26C575 26 638 21 707 9V-26C635 -11 576 -4 485 -4C311 -4 233 -56 233 -144C233 -189 249 -222 273 -257Z",
            "M515 -701C520 -683 525 -663 532 -641C437 -633 313 -636 194 -651L197 -616C318 -605 445 -603 540 -611C550 -581 560 -548 572 -517C580 -496 589 -473 598 -451C487 -438 322 -436 174 -452L176 -418C328 -406 492 -408 610 -423C637 -361 670 -298 707 -245C674 -251 602 -259 539 -267L536 -238C600 -232 679 -223 733 -209L756 -239C745 -249 734 -260 727 -272C692 -322 664 -374 641 -427C719 -438 790 -452 836 -466L831 -500C786 -485 706 -466 629 -454C618 -479 609 -503 600 -527C592 -551 581 -582 571 -615C637 -623 718 -640 776 -657L770 -690C710 -668 633 -652 562 -643C549 -689 537 -736 533 -772L494 -765C501 -748 509 -723 515 -701Z"
        ]
    },
    {
        "dec": 12368,
        "hex": "3050",
        "char": "ぐ",
        "romanization": "gu",
        "name": "HIRAGANA LETTER GU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M621 -555L596 -543C620 -505 660 -440 681 -401L708 -414C687 -451 645 -520 621 -555Z",
            "M732 -597L706 -584C733 -547 771 -489 795 -447L822 -460C799 -502 757 -566 732 -597Z",
            "M662 -746L629 -776C622 -763 603 -739 591 -727C525 -657 362 -531 290 -470C211 -402 196 -371 285 -299C379 -224 530 -93 610 -13C629 6 646 24 661 40L689 15C582 -96 420 -229 316 -313C242 -373 245 -392 311 -448C389 -513 543 -636 614 -702C624 -711 651 -737 662 -746Z"
        ]
    },
    {
        "dec": 12370,
        "hex": "3052",
        "char": "げ",
        "romanization": "ge",
        "name": "HIRAGANA LETTER GE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M191 -740C191 -729 190 -711 187 -693C176 -610 145 -460 145 -302C145 -178 175 -59 193 2L221 -3C220 -10 218 -20 217 -28C216 -40 217 -56 220 -69C230 -110 262 -211 281 -263L254 -278C236 -216 212 -149 199 -96C153 -296 184 -513 222 -688C226 -705 230 -724 233 -735Z",
            "M686 -748C689 -733 690 -720 692 -699C694 -679 697 -592 699 -527C657 -525 615 -524 573 -524C519 -524 443 -529 405 -535V-501C442 -497 520 -494 572 -494C614 -494 657 -495 700 -498V-474C700 -263 706 -129 572 -26C551 -7 519 11 495 20L527 44C747 -76 731 -254 731 -474V-500C795 -504 856 -512 909 -523V-558C854 -543 793 -534 730 -529C729 -593 726 -679 726 -700C726 -722 727 -737 728 -748Z",
            "M872 -784C892 -750 915 -694 931 -650L962 -661C944 -702 919 -760 900 -794Z",
            "M777 -755C795 -720 821 -661 835 -619L866 -631C850 -672 823 -732 805 -765Z"
        ]
    },
    {
        "dec": 12372,
        "hex": "3054",
        "char": "ご",
        "romanization": "go",
        "name": "HIRAGANA LETTER GO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M770 -777L743 -765C770 -728 806 -668 825 -627L853 -642C831 -685 795 -743 770 -777Z",
            "M870 -812L844 -799C873 -763 905 -708 928 -664L956 -677C936 -716 897 -776 870 -812Z",
            "M245 -661V-630C320 -623 402 -619 496 -619C583 -619 676 -627 741 -631V-662C675 -655 588 -649 496 -649C403 -649 314 -652 245 -661Z",
            "M242 -286L210 -290C199 -248 189 -203 189 -158C189 -47 307 10 482 10C624 10 755 -6 810 -25L809 -59C748 -36 621 -21 482 -21C310 -21 219 -76 219 -163C219 -202 226 -244 242 -286Z"
        ]
    },
    {
        "dec": 12374,
        "hex": "3056",
        "char": "ざ",
        "romanization": "za",
        "name": "HIRAGANA LETTER ZA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M747 -807L720 -794C747 -758 783 -697 802 -658L829 -672C808 -715 772 -773 747 -807Z",
            "M847 -842L821 -830C850 -792 882 -738 905 -693L932 -707C913 -746 874 -806 847 -842Z",
            "M273 -295L240 -302C218 -253 197 -207 197 -158C197 -34 308 24 479 26C579 27 657 18 723 7V-30C656 -13 573 -4 480 -5C326 -7 227 -55 227 -155C227 -206 246 -247 273 -295Z",
            "M526 -693C533 -667 544 -629 563 -579C457 -569 304 -571 166 -587L168 -553C307 -542 457 -540 573 -551C608 -458 668 -352 714 -289C673 -293 597 -299 535 -304L533 -274C592 -271 707 -262 746 -250L767 -276C755 -288 743 -300 733 -315C685 -381 633 -471 602 -555C667 -563 761 -579 830 -599L827 -633C758 -606 660 -590 592 -582C571 -642 551 -701 544 -762L507 -757C514 -738 521 -713 526 -693Z"
        ]
    },
    {
        "dec": 12376,
        "hex": "3058",
        "char": "じ",
        "romanization": "ji",
        "name": "HIRAGANA LETTER ZI = JI (not unique)",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M594 -671L568 -658C596 -619 640 -543 661 -501L688 -515C665 -564 616 -640 594 -671Z",
            "M716 -717L690 -704C720 -666 765 -590 785 -549L813 -563C788 -610 738 -687 716 -717Z",
            "M304 -752H260C265 -734 266 -711 266 -685C266 -564 255 -324 255 -165C255 -12 346 34 468 34C675 34 789 -82 860 -174L837 -201C768 -104 668 3 471 3C361 3 285 -40 285 -156C285 -327 293 -570 297 -685C298 -711 299 -729 304 -752Z"
        ]
    },
    {
        "dec": 12378,
        "hex": "305a",
        "char": "ず",
        "romanization": "zu",
        "name": "HIRAGANA LETTER ZU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M345 -327C345 -402 402 -454 467 -454C522 -454 568 -423 579 -340C574 -259 537 -206 468 -206C399 -206 345 -248 345 -327Z",
            "M548 -762C549 -758 552 -736 552 -723C553 -703 554 -666 554 -625C390 -623 203 -615 99 -612L100 -577C225 -588 406 -594 555 -596C555 -540 556 -484 556 -455C533 -474 502 -485 465 -485C384 -485 314 -410 314 -327C314 -233 380 -176 468 -176C511 -176 553 -198 577 -241C558 -120 478 -34 312 8L341 34C558 -35 613 -168 613 -305C613 -351 604 -391 585 -422C584 -467 583 -534 583 -596H631C775 -596 852 -595 901 -592V-624C863 -624 769 -626 630 -626H583C583 -666 583 -701 584 -723C584 -732 586 -756 587 -762Z",
            "M809 -783C833 -752 864 -702 885 -660L915 -675C891 -715 859 -765 837 -796Z",
            "M702 -760C726 -729 758 -676 780 -637L808 -652C786 -689 752 -743 730 -773Z"
        ]
    },
    {
        "dec": 12380,
        "hex": "305c",
        "char": "ぜ",
        "romanization": "ze",
        "name": "HIRAGANA LETTER ZE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M784 -747L757 -738C775 -703 804 -638 819 -596L849 -607C832 -649 802 -713 784 -747Z",
            "M879 -775L851 -766C871 -732 897 -670 914 -625L944 -637C927 -679 898 -742 879 -775Z",
            "M692 -738H655C658 -722 659 -698 659 -680C659 -636 659 -567 658 -513C539 -503 407 -490 301 -480C301 -544 302 -606 302 -648C302 -667 303 -692 304 -705H268C272 -680 272 -667 272 -648C272 -635 272 -562 271 -477C208 -471 157 -465 125 -463C104 -461 77 -459 43 -459L47 -420C75 -424 98 -427 128 -431C162 -435 212 -441 271 -447C271 -343 271 -230 272 -188C277 -45 290 7 490 7C594 7 714 -3 780 -10L781 -45C726 -36 607 -25 491 -25C299 -25 305 -75 301 -190C300 -223 300 -337 301 -450C411 -461 545 -474 658 -482C656 -411 651 -317 644 -281C640 -256 632 -253 602 -253C577 -253 535 -258 497 -266L496 -235C515 -231 571 -222 608 -222C647 -222 666 -236 673 -271C684 -322 686 -420 688 -484C744 -487 793 -490 830 -490H889V-528C872 -526 854 -525 830 -524C788 -522 740 -519 689 -515C690 -565 690 -633 690 -680C690 -696 691 -720 692 -738Z"
        ]
    },
    {
        "dec": 12382,
        "hex": "305e",
        "char": "ぞ",
        "romanization": "zo",
        "name": "HIRAGANA LETTER ZO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M709 -641L682 -628C711 -589 742 -536 763 -493L790 -507C767 -556 730 -610 709 -641Z",
            "M807 -676L781 -663C811 -624 842 -571 864 -529L891 -543C867 -590 829 -646 807 -676Z",
            "M252 -725L254 -686C266 -687 290 -689 317 -691C361 -694 580 -704 627 -707C563 -652 382 -493 261 -403C212 -397 140 -389 86 -384L89 -350C225 -372 379 -387 498 -398C433 -371 345 -292 345 -186C345 -47 461 28 688 18L695 -16C661 -13 624 -11 563 -19C472 -31 376 -68 376 -188C376 -292 488 -388 589 -403C646 -412 739 -414 837 -410V-444C675 -444 483 -428 316 -409C406 -481 590 -635 666 -698C674 -705 688 -715 693 -719L672 -743C662 -740 647 -738 634 -737C581 -731 359 -722 315 -722C288 -722 269 -723 252 -725Z"
        ]
    },
    {
        "dec": 12384,
        "hex": "3060",
        "char": "だ",
        "romanization": "da",
        "name": "HIRAGANA LETTER DA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M751 -721L725 -708C751 -672 789 -611 807 -572L835 -586C813 -629 777 -687 751 -721Z",
            "M851 -756L826 -743C854 -706 888 -652 910 -607L938 -621C918 -660 879 -720 851 -756Z",
            "M511 -222L479 -225C470 -181 466 -154 466 -125C466 -30 548 10 691 10C756 10 820 4 871 -4L872 -41C821 -29 755 -22 692 -22C519 -22 496 -78 496 -127C496 -156 501 -186 511 -222Z",
            "M398 -752L358 -757C358 -740 355 -713 352 -694C349 -672 340 -629 330 -580C286 -576 245 -574 211 -574C179 -574 141 -575 99 -581L100 -547C137 -544 170 -543 209 -543C243 -543 282 -545 324 -549C314 -505 304 -459 294 -422C258 -283 193 -90 133 14L172 27C221 -71 288 -276 323 -417C336 -462 347 -508 356 -552C427 -559 503 -572 572 -587V-621C508 -604 433 -591 363 -583C371 -622 378 -658 384 -690C387 -706 392 -737 398 -752Z",
            "M522 -453V-420C581 -426 641 -430 696 -430C752 -430 807 -426 858 -419L860 -452C811 -458 751 -460 692 -460C630 -460 570 -457 522 -453Z"
        ]
    },
    {
        "dec": 12386,
        "hex": "3062",
        "char": "ぢ",
        "romanization": "dji",
        "name": "HIRAGANA LETTER DI = JI (not unique)",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M352 -755C353 -734 351 -713 348 -691C345 -670 341 -640 334 -605C319 -604 305 -604 292 -604C235 -604 163 -611 123 -618L124 -584C179 -579 234 -574 293 -574H328C305 -462 265 -309 211 -205L245 -193C254 -211 264 -226 278 -241C349 -323 465 -363 586 -363C719 -363 786 -300 786 -212C786 -35 564 14 327 -14L333 20C611 50 818 -24 818 -213C818 -316 738 -392 587 -392C476 -392 372 -362 281 -287C307 -348 338 -475 359 -575C480 -579 636 -594 764 -617V-653C643 -625 486 -610 365 -605C372 -639 377 -668 380 -688C383 -707 388 -735 393 -752Z",
            "M842 -808C871 -772 904 -717 926 -673L954 -686C934 -724 895 -785 868 -821Z",
            "M741 -773C768 -737 805 -676 823 -637L851 -651C829 -694 793 -752 768 -786Z"
        ]
    },
    {
        "dec": 12389,
        "hex": "3065",
        "char": "づ",
        "romanization": "dzu",
        "name": "HIRAGANA LETTER DU = ZU (not unique)",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M748 -758L722 -745C748 -708 786 -648 804 -608L832 -622C810 -666 774 -723 748 -758Z",
            "M848 -792L823 -780C851 -743 885 -688 907 -644L935 -658C915 -696 876 -757 848 -792Z",
            "M100 -476L116 -437C169 -455 437 -573 615 -573C762 -573 848 -483 848 -369C848 -140 600 -62 353 -54L365 -19C625 -35 881 -121 881 -369C881 -516 762 -603 614 -603C471 -603 286 -532 195 -503C157 -491 135 -483 100 -476Z"
        ]
    },
    {
        "dec": 12391,
        "hex": "3067",
        "char": "で",
        "romanization": "de",
        "name": "HIRAGANA LETTER DE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M711 -514L684 -502C713 -463 748 -402 768 -360L795 -374C771 -422 732 -484 711 -514Z",
            "M816 -552L790 -539C820 -500 855 -440 875 -398L903 -411C878 -459 838 -521 816 -552Z",
            "M82 -625L87 -584C186 -604 481 -633 601 -646C488 -589 371 -451 371 -286C371 -60 600 24 759 25L770 -9C617 -12 403 -73 403 -294C403 -409 495 -580 656 -639C703 -656 798 -657 857 -657V-690C796 -688 709 -683 596 -673C444 -660 206 -636 161 -630C141 -628 118 -626 82 -625Z"
        ]
    },
    {
        "dec": 12393,
        "hex": "3069",
        "char": "ど",
        "romanization": "do",
        "name": "HIRAGANA LETTER DO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M222 -728C270 -618 326 -491 370 -416L361 -410C246 -334 193 -255 193 -167C193 -41 314 14 486 14C604 14 727 3 791 -9V-47C725 -29 596 -17 483 -17C310 -17 224 -78 224 -170C224 -250 278 -321 378 -387C480 -455 609 -517 681 -554C703 -567 720 -575 734 -583L716 -613C700 -601 687 -593 666 -581C605 -547 495 -496 397 -433C353 -510 297 -629 256 -742Z",
            "M828 -769C857 -733 890 -678 912 -634L940 -648C920 -686 881 -747 854 -782Z",
            "M727 -735C754 -698 791 -638 809 -598L837 -611C815 -656 779 -712 754 -748Z"
        ]
    },
    {
        "dec": 12400,
        "hex": "3070",
        "char": "ば",
        "romanization": "ba",
        "name": "HIRAGANA LETTER BA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M792 -765L763 -756C781 -720 810 -656 824 -613L857 -626C840 -667 810 -733 792 -765Z",
            "M887 -794L858 -784C878 -750 904 -688 920 -644L953 -657C935 -698 906 -760 887 -794Z",
            "M403 -106C403 -164 466 -204 557 -204C601 -204 641 -197 678 -185C679 -164 679 -147 679 -134C679 -61 653 -12 548 -12C459 -12 403 -45 403 -106Z",
            "M703 -741H664C666 -726 666 -704 666 -685C665 -651 665 -602 665 -551C627 -549 588 -548 550 -548C493 -548 444 -551 390 -556V-524C446 -520 492 -517 547 -517C586 -517 626 -518 665 -520C665 -433 673 -306 677 -216C640 -226 602 -232 560 -232C437 -232 373 -169 373 -104C373 -31 435 19 561 19C685 19 709 -61 709 -121C709 -137 709 -155 708 -173C774 -145 831 -100 887 -49L906 -80C850 -129 784 -177 707 -206C703 -306 695 -428 695 -522C759 -526 821 -533 881 -543V-575C825 -564 761 -557 695 -552C695 -597 695 -653 697 -686C698 -704 700 -721 703 -741Z",
            "M214 -738L176 -742C176 -731 174 -717 171 -698C159 -612 123 -423 123 -283C123 -150 139 -47 158 25L188 22C187 16 185 4 184 -5C183 -18 185 -34 188 -48C196 -90 235 -190 258 -246L235 -264C215 -215 185 -134 167 -79C156 -155 152 -208 152 -284C152 -405 179 -578 203 -694C206 -712 210 -726 214 -738Z"
        ]
    },
    {
        "dec": 12403,
        "hex": "3073",
        "char": "び",
        "romanization": "bi",
        "name": "HIRAGANA LETTER BI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M785 -754L760 -744C781 -710 814 -649 833 -607L863 -621C842 -661 806 -722 785 -754Z",
            "M880 -794L855 -781C878 -749 909 -691 929 -648L959 -661C936 -703 901 -762 880 -794Z",
            "M703 -699L669 -689C677 -658 686 -629 694 -601C744 -232 650 7 415 7C308 7 201 -39 201 -201C201 -415 369 -606 426 -648C440 -653 468 -658 480 -661L471 -693C420 -672 253 -647 172 -644C154 -643 134 -643 122 -644L125 -605C142 -606 160 -609 177 -611C219 -617 316 -629 378 -642C296 -562 171 -402 171 -196C171 -48 262 39 414 39C683 39 757 -229 728 -505C766 -409 814 -330 884 -258L909 -284C769 -407 725 -584 703 -699Z"
        ]
    },
    {
        "dec": 12406,
        "hex": "3076",
        "char": "ぶ",
        "romanization": "bu",
        "name": "HIRAGANA LETTER BU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M745 -645L718 -633C745 -595 782 -536 801 -495L828 -509C807 -553 770 -610 745 -645Z",
            "M845 -680L819 -667C849 -631 881 -576 904 -532L931 -545C911 -584 872 -644 845 -680Z",
            "M507 -525L529 -502C562 -526 598 -553 619 -571L610 -596C560 -640 448 -703 378 -736L359 -711C436 -677 538 -619 581 -579C565 -565 535 -542 507 -525Z",
            "M297 -41L300 -5C341 3 391 11 461 11C506 11 605 -5 605 -115C605 -201 535 -287 455 -374C435 -397 410 -426 392 -446L369 -426C391 -403 417 -377 436 -355C501 -279 575 -191 575 -118C575 -38 501 -20 461 -20C386 -20 343 -28 297 -41Z",
            "M884 -41L912 -57C881 -138 809 -271 736 -353L711 -338C781 -268 852 -130 884 -41Z",
            "M303 -237L285 -260C232 -201 110 -108 33 -67L52 -41C140 -90 245 -177 303 -237Z"
        ]
    },
    {
        "dec": 12409,
        "hex": "3079",
        "char": "べ",
        "romanization": "be",
        "name": "HIRAGANA LETTER BE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M671 -655L644 -643C673 -603 718 -527 737 -486L766 -499C741 -548 693 -624 671 -655Z",
            "M793 -701L768 -689C797 -650 841 -575 863 -534L890 -548C866 -595 816 -672 793 -701Z",
            "M71 -235L101 -207C115 -223 137 -246 155 -267C212 -333 303 -451 356 -515C395 -562 411 -571 460 -517C505 -468 581 -373 648 -300C719 -222 815 -116 893 -42L919 -70C835 -144 724 -261 669 -323C601 -394 528 -488 477 -542C420 -606 379 -593 333 -537C277 -469 189 -347 130 -289C108 -266 91 -249 71 -235Z"
        ]
    },
    {
        "dec": 12412,
        "hex": "307c",
        "char": "ぼ",
        "romanization": "bo",
        "name": "HIRAGANA LETTER BO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M804 -757L776 -749C785 -733 795 -711 805 -687C696 -674 538 -666 407 -677V-645C480 -641 563 -642 641 -645V-448C560 -445 474 -445 391 -451V-419C472 -414 559 -414 641 -418C642 -355 647 -275 651 -212C618 -220 582 -224 544 -224C417 -224 370 -160 370 -108C370 -29 439 13 542 13C626 13 683 -24 683 -96C683 -115 682 -141 681 -172C752 -147 818 -103 875 -44L894 -77C835 -130 765 -179 679 -204C676 -272 672 -353 671 -419C745 -422 814 -428 869 -433V-466C812 -459 744 -453 671 -449V-647C726 -650 777 -654 818 -658C826 -639 833 -621 838 -606L868 -618C853 -659 821 -724 804 -757Z",
            "M900 -788L871 -777C892 -743 917 -681 934 -637L965 -649C948 -690 918 -755 900 -788Z",
            "M399 -108C399 -160 459 -195 544 -195C581 -195 617 -190 653 -180C654 -156 655 -136 655 -123C655 -65 639 -17 544 -17C455 -17 399 -46 399 -108Z",
            "M205 -726L167 -729C167 -719 166 -705 163 -686C150 -600 114 -421 114 -280C114 -148 129 -45 149 27L179 24C178 18 176 6 175 -3C174 -16 177 -31 180 -45C188 -87 227 -190 248 -244L226 -263C206 -216 176 -130 158 -76C148 -152 144 -206 144 -281C144 -403 170 -566 194 -682C197 -700 201 -713 205 -726Z"
        ]
    },
    {
        "dec": 12401,
        "hex": "3071",
        "char": "ぱ",
        "romanization": "pa",
        "name": "HIRAGANA LETTER PA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M789 -714C789 -753 819 -784 859 -784C897 -784 928 -753 928 -714C928 -675 897 -645 859 -645C819 -645 789 -675 789 -714Z",
            "M760 -714C760 -659 803 -615 859 -615C914 -615 959 -659 959 -714C959 -769 914 -814 859 -814C803 -814 760 -769 760 -714Z",
            "M403 -106C403 -164 466 -204 557 -204C601 -204 641 -197 678 -185C679 -164 679 -147 679 -134C679 -61 653 -12 548 -12C459 -12 403 -45 403 -106Z",
            "M703 -741H664C666 -726 666 -704 666 -685C665 -651 665 -602 665 -551C627 -549 588 -548 550 -548C493 -548 444 -551 390 -556V-524C446 -520 492 -517 547 -517C586 -517 626 -518 665 -520C665 -433 673 -306 677 -216C640 -226 602 -232 560 -232C437 -232 373 -169 373 -104C373 -31 435 19 561 19C685 19 709 -61 709 -121C709 -137 709 -155 708 -173C774 -145 831 -100 887 -49L906 -80C850 -129 784 -177 707 -206C703 -306 695 -428 695 -522C759 -526 821 -533 881 -543V-575C825 -564 761 -557 695 -552C695 -597 695 -653 697 -686C698 -704 700 -721 703 -741Z",
            "M214 -738L176 -742C176 -731 174 -717 171 -698C159 -612 123 -423 123 -283C123 -150 139 -47 158 25L188 22C187 16 185 4 184 -5C183 -18 185 -34 188 -48C196 -90 235 -190 258 -246L235 -264C215 -215 185 -134 167 -79C156 -155 152 -208 152 -284C152 -405 179 -578 203 -694C206 -712 210 -726 214 -738Z"
        ]
    },
    {
        "dec": 12404,
        "hex": "3074",
        "char": "ぴ",
        "romanization": "pi",
        "name": "HIRAGANA LETTER PI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M789 -693C789 -734 821 -766 862 -766C902 -766 934 -734 934 -693C934 -653 902 -620 862 -620C820 -620 789 -653 789 -693Z",
            "M760 -693C760 -637 805 -591 862 -591C918 -591 965 -637 965 -693C965 -750 918 -796 862 -796C805 -796 760 -750 760 -693Z",
            "M703 -699L669 -689C677 -658 686 -629 694 -601C744 -232 650 7 415 7C308 7 201 -39 201 -201C201 -415 369 -606 426 -648C440 -653 468 -658 480 -661L471 -693C420 -672 253 -647 172 -644C154 -643 134 -643 122 -644L125 -605C142 -606 160 -609 177 -611C219 -617 316 -629 378 -642C296 -562 171 -402 171 -196C171 -48 262 39 414 39C683 39 757 -229 728 -505C766 -409 814 -330 884 -258L909 -284C769 -407 725 -584 703 -699Z"
        ]
    },
    {
        "dec": 12407,
        "hex": "3077",
        "char": "ぷ",
        "romanization": "pu",
        "name": "HIRAGANA LETTER PU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M752 -588C752 -628 784 -663 824 -663C866 -663 900 -628 900 -588C900 -546 866 -513 824 -513C784 -513 752 -546 752 -588Z",
            "M720 -588C720 -529 767 -484 824 -484C883 -484 929 -529 929 -588C929 -645 883 -692 824 -692C767 -692 720 -645 720 -588Z",
            "M507 -525L529 -502C562 -526 598 -553 619 -571L610 -596C560 -640 448 -703 378 -736L359 -711C436 -677 538 -619 581 -579C565 -565 535 -542 507 -525Z",
            "M297 -41L300 -5C341 3 391 11 461 11C506 11 605 -5 605 -115C605 -201 535 -287 455 -374C435 -397 410 -426 392 -446L369 -426C391 -403 417 -377 436 -355C501 -279 575 -191 575 -118C575 -38 501 -20 461 -20C386 -20 343 -28 297 -41Z",
            "M884 -41L912 -57C881 -138 809 -271 736 -353L711 -338C781 -268 852 -130 884 -41Z",
            "M303 -237L285 -260C232 -201 110 -108 33 -67L52 -41C140 -90 245 -177 303 -237Z"
        ]
    },
    {
        "dec": 12410,
        "hex": "307a",
        "char": "ぺ",
        "romanization": "pe",
        "name": "HIRAGANA LETTER PE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M679 -576C679 -621 716 -658 762 -658C807 -658 843 -621 843 -576C843 -531 807 -493 762 -493C716 -493 679 -531 679 -576Z",
            "M650 -576C650 -514 700 -464 762 -464C822 -464 873 -514 873 -576C873 -638 822 -688 762 -688C700 -688 650 -638 650 -576Z",
            "M71 -235L101 -207C115 -223 137 -246 155 -267C212 -333 303 -451 356 -515C395 -562 411 -571 460 -517C505 -468 581 -373 648 -300C719 -222 815 -116 893 -42L919 -70C835 -144 724 -261 669 -323C601 -394 528 -488 477 -542C420 -606 379 -593 333 -537C277 -469 189 -347 130 -289C108 -266 91 -249 71 -235Z"
        ]
    },
    {
        "dec": 12413,
        "hex": "307d",
        "char": "ぽ",
        "romanization": "po",
        "name": "HIRAGANA LETTER PO",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M811 -741C811 -780 841 -811 880 -811C919 -811 951 -780 951 -741C951 -702 919 -672 880 -672C841 -672 811 -702 811 -741Z",
            "M781 -741C781 -721 787 -702 797 -686C688 -674 534 -667 407 -677V-645C480 -641 563 -642 641 -645V-448C560 -445 474 -445 391 -451V-419C472 -414 559 -414 641 -418C642 -355 647 -275 651 -212C618 -220 582 -224 544 -224C417 -224 370 -160 370 -108C370 -29 439 13 542 13C626 13 683 -24 683 -96C683 -115 682 -141 681 -172C752 -147 818 -103 875 -44L894 -77C835 -130 765 -179 679 -204C676 -272 672 -353 671 -419C745 -422 814 -428 869 -433V-466C812 -459 744 -453 671 -449V-647C726 -650 778 -654 819 -658V-662C836 -649 857 -642 880 -642C935 -642 980 -685 980 -741C980 -796 935 -841 880 -841C824 -841 781 -796 781 -741Z",
            "M399 -108C399 -160 459 -195 544 -195C581 -195 617 -190 653 -180C654 -156 655 -136 655 -123C655 -65 639 -17 544 -17C455 -17 399 -46 399 -108Z",
            "M205 -726L167 -729C167 -719 166 -705 163 -686C150 -600 114 -421 114 -280C114 -148 129 -45 149 27L179 24C178 18 176 6 175 -3C174 -16 177 -31 180 -45C188 -87 227 -190 248 -244L226 -263C206 -216 176 -130 158 -76C148 -152 144 -206 144 -281C144 -403 170 -566 194 -682C197 -700 201 -713 205 -726Z"
        ]
    },
    {
        "dec": 12435,
        "hex": "3093",
        "char": "ん",
        "romanization": "n",
        "name": "HIRAGANA LETTER N",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M519 -740L482 -754C475 -738 468 -722 457 -701C403 -606 176 -202 104 -2L138 9C149 -36 198 -158 232 -217C273 -293 364 -383 455 -383C508 -383 540 -353 541 -302C544 -235 541 -163 544 -101C546 -56 564 11 665 11C798 11 875 -96 927 -244L899 -265C876 -177 802 -20 669 -20C616 -20 576 -45 574 -103C572 -158 573 -230 571 -302C569 -375 519 -412 462 -412C405 -412 341 -385 284 -322C337 -425 448 -624 488 -690C499 -710 511 -729 519 -740Z"
        ]
    },
    {
        "dec": 12436,
        "hex": "3094",
        "char": "ゔ",
        "romanization": "v",
        "name": "HIRAGANA LETTER VU",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M743 -669L717 -656C743 -619 780 -559 799 -519L826 -534C805 -577 769 -635 743 -669Z",
            "M843 -703L818 -691C846 -654 879 -599 902 -555L929 -569C910 -607 871 -668 843 -703Z",
            "M721 -343C721 -146 542 -42 309 -9L331 22C566 -17 752 -128 752 -343C752 -470 653 -538 530 -538C419 -538 306 -503 240 -488C213 -482 188 -477 167 -475L180 -432C199 -440 218 -446 250 -456C316 -475 411 -507 530 -507C639 -507 721 -444 721 -343Z",
            "M284 -757L279 -725C387 -705 570 -685 673 -678L678 -710C589 -712 386 -733 284 -757Z"
        ]
    },
    {
        "dec": 12437,
        "hex": "3095",
        "char": "ゕ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL KA",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M546 -689L507 -692C505 -633 496 -571 484 -520C432 -515 379 -509 352 -508C332 -507 313 -507 294 -508L298 -471C313 -473 337 -476 352 -477C378 -479 427 -485 478 -490C477 -489 477 -488 477 -487C455 -391 393 -201 309 -89L343 -76C434 -218 483 -381 509 -486C510 -488 510 -490 511 -493C552 -496 590 -499 613 -499C664 -499 704 -486 704 -409C704 -316 687 -198 662 -148C644 -112 619 -107 593 -107C565 -107 530 -111 492 -124L497 -86C531 -81 568 -76 601 -76C636 -76 666 -88 690 -136C718 -191 735 -312 735 -413C735 -515 673 -529 614 -529C595 -529 558 -526 517 -522C528 -576 537 -636 540 -657C542 -669 544 -679 546 -689Z",
            "M807 -596L781 -582C833 -517 903 -388 925 -314L957 -329C929 -401 859 -535 807 -596Z"
        ]
    },
    {
        "dec": 12438,
        "hex": "3096",
        "char": "ゖ",
        "romanization": "",
        "name": "HIRAGANA LETTER SMALL KE",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M802 -682H760C762 -678 765 -650 766 -637C768 -613 768 -553 769 -511C738 -509 704 -508 667 -508C613 -508 558 -514 533 -519V-484C571 -481 615 -478 666 -478C706 -478 740 -479 770 -481C774 -310 777 -195 671 -113C655 -100 631 -85 608 -79L639 -53C819 -148 804 -318 801 -483C849 -487 890 -493 936 -500V-536C892 -525 849 -518 800 -513C800 -543 798 -619 799 -637C800 -652 800 -667 802 -682Z",
            "M405 -672L368 -677C368 -670 367 -655 365 -641C353 -575 329 -457 329 -334C329 -234 352 -134 368 -86L394 -91C393 -96 391 -109 391 -117C390 -127 391 -139 394 -148C400 -181 426 -261 440 -303L415 -314C401 -265 383 -221 373 -180C345 -338 366 -499 397 -636C400 -650 402 -663 405 -672Z"
        ]
    },
    {
        "dec": 12441,
        "hex": "3099",
        "char": "゙",
        "romanization": "",
        "name": "COMBINING KATAKANA-HIRAGANA VOICED SOUND MARK",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M766 -99L739 -88C764 -56 813 28 834 68L862 56C840 16 789 -67 766 -99Z",
            "M886 -144L860 -133C886 -101 932 -20 955 22L983 9C959 -32 909 -113 886 -144Z"
        ]
    },
    {
        "dec": 12442,
        "hex": "309a",
        "char": "゚",
        "romanization": "",
        "name": "COMBINING KATAKANA-HIRAGANA SEMIVOICED SOUND MARK",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M760 -50C760 10 807 58 869 58C930 58 977 10 977 -50C977 -111 930 -159 869 -159C807 -159 760 -110 760 -50Z",
            "M788 -50C788 -92 826 -130 869 -130C911 -130 949 -92 949 -50C949 -9 911 30 869 30C826 30 788 -10 788 -50Z"
        ]
    },
    {
        "dec": 12445,
        "hex": "309d",
        "char": "ゝ",
        "romanization": "",
        "name": "HIRAGANA ITERATION MARK",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M710 -210L719 -234C654 -326 512 -492 381 -611L356 -591C467 -487 617 -326 679 -233C627 -213 474 -153 413 -129L427 -100C486 -125 630 -183 710 -210Z"
        ]
    },
    {
        "dec": 12446,
        "hex": "309e",
        "char": "ゞ",
        "romanization": "",
        "name": "HIRAGANA VOICED ITERATION MARK",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M569 -677L540 -665C565 -632 610 -557 630 -517L661 -531C638 -571 591 -646 569 -677Z",
            "M691 -718L663 -705C688 -673 730 -600 753 -558L783 -573C760 -614 714 -686 691 -718Z",
            "M671 -192L680 -217C615 -308 473 -474 342 -593L317 -574C428 -470 578 -308 640 -216C588 -195 435 -135 374 -111L388 -82C447 -107 591 -166 671 -192Z"
        ]
    },
    {
        "dec": 12447,
        "hex": "309f",
        "char": "ゟ",
        "romanization": "",
        "name": "HIRAGANA DIGRAPH YORI",
        "viewBox": "0 -1000 1000 1000",
        "paths": [
            "M534 -393C448 -393 385 -344 348 -316C405 -403 460 -511 506 -613C603 -597 726 -564 815 -529L831 -563C735 -598 646 -620 519 -641C537 -682 553 -721 567 -756C576 -778 588 -802 595 -817L550 -831C547 -815 543 -794 536 -772C483 -626 416 -483 322 -326C263 -228 155 -71 94 -7L130 13C186 -56 258 -165 301 -227C348 -288 427 -364 533 -364C640 -364 687 -273 667 -182C643 -72 551 18 436 18C372 18 334 -12 297 -49L286 -14C334 32 383 48 439 48C565 48 666 -51 695 -167C722 -271 671 -393 534 -393Z"
        ]
    }
]
export default hiragana;